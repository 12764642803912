<template>
  <div class="knowledge">
    <div class="column-L animated fadeInUp">
      <div class="result" v-show="!diseaseInfoShow">
        <div class="result-top">
          <div class="result-search">
            <div class="name calamity-type-name" style="margin-right: 10px">
              <el-cascader
                placeholder="请选择分类"
                :options="optionList"
                clearable
                :show-all-levels="false"
                @change="changeCascader"
              ></el-cascader>
            </div>

            <div class="name calamity-type-name">
              <el-input
                v-model.trim="calamityTypeName"
                autocomplete="off"
                placeholder="请输入你需要搜索的关键词"
                maxlength="20"
              ></el-input>
            </div>
            <div class="searchBtn" @click="getCalamitySearch">查询</div>
          </div>
        </div>
        <el-tabs v-model="diseaseTypeID" @tab-click="handleClick">
          <el-tab-pane
            :label="'虫害数据(' + count + ')'"
            name="1"
          ></el-tab-pane>
          <el-tab-pane
            :label="'病害数据(' + diseaseCount + ')'"
            name="2"
          ></el-tab-pane>
        </el-tabs>
        <!-- 病虫 -->
        <div class="show-box" v-show="!calamityTypeIndex">
          <div class="disease-list" v-show="list.length">
            <div class="disease-list-items insect">
              <!-- v-blockLoadMore="loadMoreDisease" -->
              <div class="insect-box">
                <div
                  :class="{
                    'disease-list-items-main-items insect': true,
                  }"
                  v-for="t in list"
                  :key="t.id"
                  @click="changeItems(t.id)"
                >
                  <el-image class="imgs" :src="t.coverImg" fit="cover">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <span> {{ t.diseaseName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="disease-list" v-show="!list.length">
            <MyNothing></MyNothing>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            :page-size="15"
            @current-change="handleCurrentChange"
            v-if="list.length"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 病虫信息 -->

      <div class="info-box" v-show="diseaseInfoShow && !calamityTypeIndex">
        <div class="info-title">
          <div class="info-left-line">{{ diseaseInfo.diseaseName }}</div>
          <div class="return-btn" @click="diseaseInfoShow = !diseaseInfoShow">
            <img src="../../assets/image/know/back.png" alt="" /><span
              >返回</span
            >
          </div>
        </div>
        <div class="cell-title">
          <span>简介</span>
        </div>
        <div class="other-col">
          <span class="content-title">英文名</span>
          <span class="content-main">{{ diseaseInfo.diseaseNameEng }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">异 名</span>
          <span class="content-main">{{ diseaseInfo.diseaseNameOther }}</span>
        </div>
        <div class="other-col">
          <span class="content-title">简 介</span>
          <span class="content-main" style="line-height: 30px">{{
            diseaseInfo.diseaseIntro
          }}</span>
        </div>
        <div class="cell-title" v-if="diseaseInfo.imgsList[0]">
          <span>缩图</span>
        </div>
        <div class="imgs-list" v-if="diseaseInfo.imgsList[0]">
          <el-image
            class="el-img"
            :src="t"
            fit="cover"
            v-for="(t, i) in diseaseInfo.imgsList"
            :key="i"
            :preview-src-list="[t]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="cell-title">
          <span>详细资料</span>
        </div>
        <div class="means-list">
          <div
            class="means-list-items"
            v-for="(item, index) in meansList"
            :key="index"
          >
            <p class="p-title">{{ item.title ? item.title : "其他" }}</p>
            <p class="p-value">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryDiseaseListApi, queryDiseaseInfoApi } from "@/request/api.js";

export default {
  components: {
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      optionList: [
        {
          label: "农作物-糖料",
          children: [
            { value: "甘蔗", label: "甘蔗" },
            { value: "甜菜", label: "甜菜" },
          ],
        },
        {
          label: "农作物-油料",
          children: [
            { value: "向日葵", label: "向日葵" },
            { value: "芝麻", label: "芝麻" },
            { value: "油菜", label: "油菜" },
            { value: "花生", label: "花生" },
            { value: "大豆", label: "大豆" },
          ],
        },
        {
          label: "农作物-纤维类",
          children: [
            { value: "大麻", label: "大麻" },
            { value: "黄麻", label: "黄麻" },
            { value: "红麻", label: "红麻" },
            { value: "亚麻", label: "亚麻" },
            { value: "棉花", label: "棉花" },
          ],
        },
        {
          label: "农作物-薯类",
          children: [
            { value: "马铃薯", label: "马铃薯" },
            { value: "甘薯", label: "甘薯" },
          ],
        },
        {
          label: "农作物-禾本科",
          children: [
            { value: "高梁", label: "高梁" },
            { value: "玉米", label: "玉米" },
            { value: "大麦", label: "大麦" },
            { value: "小麦", label: "小麦" },
            { value: "水稻", label: "水稻" },
          ],
        },
        {
          label: "农作物-桑树",
          children: [{ value: "桑树", label: "桑树" }],
        },
        {
          label: "农作物-烟草",
          children: [{ value: "烟草", label: "烟草" }],
        },
        {
          label: "农作物-饮料作物",
          children: [{ value: "茶树", label: "茶树" }],
        },
        {
          label: "果树-多年生草本类",
          children: [
            { value: "草莓", label: "草莓" },
            { value: "香蕉", label: "香蕉" },
          ],
        },
        {
          label: "果树-热带及亚热带类",
          children: [
            { value: "枇杷", label: "枇杷" },
            { value: "芒果", label: "芒果" },
            { value: "龙眼", label: "龙眼" },
            { value: "荔枝", label: "荔枝" },
          ],
        },
        {
          label: "果树-柑桔类",
          children: [{ value: "柑橘", label: "柑橘" }],
        },
        {
          label: "果树-杂果类",
          children: [
            { value: "石榴", label: "石榴" },
            { value: "柿树", label: "柿树" },
            { value: "枣树", label: "枣树" },
          ],
        },
        {
          label: "果树-坚果类",
          children: [
            { value: "核桃", label: "核桃" },
            { value: "板栗", label: "板栗" },
          ],
        },
        {
          label: "果树-浆果类",
          children: [
            { value: "猕猴桃", label: "猕猴桃" },
            { value: "葡萄", label: "葡萄" },
          ],
        },
        {
          label: "果树-核果类",
          children: [
            { value: "梅树", label: "梅树" },
            { value: "樱桃", label: "樱桃" },
            { value: "杏树", label: "杏树" },
            { value: "李树", label: "李树" },
            { value: "桃树", label: "桃树" },
          ],
        },
        {
          label: "果树-仁果类",
          children: [
            { value: "山楂", label: "山楂" },
            { value: "梨树", label: "梨树" },
            { value: "苹果", label: "苹果" },
          ],
        },
        {
          label: "蓏果类作物-蓏果类作物",
          children: [
            { value: "甜瓜", label: "甜瓜" },
            { value: "西瓜", label: "西瓜" },
          ],
        },
        {
          label: "蔬菜-食用菌",
          children: [
            { value: "杏鲍菇", label: "杏鲍菇" },
            { value: "鸡腿菇", label: "鸡腿菇" },
            { value: "平菇", label: "平菇" },
            { value: "香菇", label: "香菇" },
            { value: "蘑菇", label: "蘑菇" },
          ],
        },
        {
          label: "蔬菜-水生菜类",
          children: [
            { value: "荸荠", label: "荸荠" },
            { value: "慈姑", label: "慈姑" },
            { value: "茭白", label: "茭白" },
            { value: "莲藕", label: "莲藕" },
          ],
        },
        {
          label: "蔬菜-多年生菜类",
          children: [
            { value: "芦笋", label: "芦笋" },
            { value: "枸杞", label: "枸杞" },
            { value: "黄花菜", label: "黄花菜" },
          ],
        },
        {
          label: "蔬菜-豆荚类",
          children: [
            { value: "扁豆", label: "扁豆" },
            { value: "豌豆", label: "豌豆" },
            { value: "毛豆", label: "毛豆" },
            { value: "豇豆", label: "豇豆" },
            { value: "菜豆", label: "菜豆" },
          ],
        },
        {
          label: "蔬菜-瓜类",
          children: [
            { value: "丝瓜", label: "丝瓜" },
            { value: "瓠瓜", label: "瓠瓜" },
            { value: "冬瓜", label: "冬瓜" },
            { value: "西葫芦", label: "西葫芦" },
            { value: "黄瓜", label: "黄瓜" },
          ],
        },
        {
          label: "蔬菜-茄果类",
          children: [
            { value: "茄子", label: "茄子" },
            { value: "辣椒", label: "辣椒" },
            { value: "番茄", label: "番茄" },
          ],
        },
        {
          label: "蔬菜-绿叶菜类",
          children: [
            { value: "苋菜", label: "苋菜" },
            { value: "生菜", label: "生菜" },
            { value: "莴笋", label: "莴笋" },
            { value: "芹菜", label: "芹菜" },
            { value: "菠菜", label: "菠菜" },
          ],
        },
        {
          label: "蔬菜-甘蓝类",
          children: [
            { value: "芥蓝", label: "芥蓝" },
            { value: "紫甘蓝", label: "紫甘蓝" },
            { value: "青花菜", label: "青花菜" },
            { value: "花椰菜", label: "花椰菜" },
            { value: "甘蓝", label: "甘蓝" },
          ],
        },
        {
          label: "蔬菜-白菜类",
          children: [
            { value: "黄心乌", label: "黄心乌" },
            { value: "青菜", label: "青菜" },
            { value: "大白菜", label: "大白菜" },
          ],
        },
        {
          label: "蔬菜-葱蒜类",
          children: [
            { value: "韭菜", label: "韭菜" },
            { value: "大蒜", label: "大蒜" },
            { value: "葱", label: "葱" },
          ],
        },
        {
          label: "蔬菜-薯芋类",
          children: [
            { value: "菊芋", label: "菊芋" },
            { value: "山药", label: "山药" },
            { value: "芋", label: "芋" },
            { value: "生姜", label: "生姜" },
            { value: "魔芋", label: "魔芋" },
          ],
        },
        {
          label: "蔬菜-根菜类",
          children: [
            { value: "牛蒡", label: "牛蒡" },
            { value: "胡萝卜", label: "胡萝卜" },
            { value: "萝卜", label: "萝卜" },
          ],
        },
      ],
      diseaseTypeID: "1",
      list: [],
      count: 0,
      diseaseCount: 0,
      pn: 1,
      ps: 15,
      meansList: [],
      diseaseInfoShow: false,
      calamityTypeName: undefined,
      cropName: undefined,
      calamityTypeIndex: 0,
      current: 1,
      diseaseInfo: {
        diseaseName: "",
        diseaseNameEng: "",
        diseaseNameOther: "",
        diseaseIntro: "",
        diseaseTypeID: 1,
        diseaseDetails: {
          DamageSym: "",
          OFactor: "",
          Pathogen: "",
          Cycle: "",
          Morphology: "",
          Habits: "",
          CMethod: "",
        },
        diseaseImgList: [],
        imgsList: [],
      },
    };
  },
  watch: {
    calamityTypeIndex: {
      handler() {
        this.diseaseInfoShow = false;
      },
    },
    diseaseInfoShow(newVal) {
      if (!newVal) {
        this.meansList = [];
      }
    },
  },

  created() {
    this.commitForm();
  },

  methods: {
    changeCascader(name) {
      this.cropName = name[1];
      this.getCalamitySearch();
    },
    handleClick(tab) {
      this.diseaseTypeID = tab.name;
      this.commitForm();
    },

    async changeItems(disease_id) {
      const { code, results } = await queryDiseaseInfoApi({
        id: disease_id,
      });
      if (code !== 0) return;
      if (results.diseaseImgList.startsWith("http")) {
        results["imgsList"] = [results.diseaseImgList];
      } else {
        results.diseaseImgList = results.diseaseImgList.split(",");
        results["imgsList"] = results.diseaseImgList.map((item) => {
          if (item) {
            item = "https://data.darsing.net/" + item;
          }
        });
      }
      results.diseaseDetails = JSON.parse(results.diseaseDetails);
      for (const key in results.diseaseDetails) {
        if (Object.hasOwnProperty.call(results.diseaseDetails, key)) {
          let element = results.diseaseDetails[key];
          let obj = {};
          if (element) {
            if (element.indexOf("]") != -1) {
              obj.title = element.slice(
                element.indexOf("[") + 1,
                element.indexOf("]")
              );
              obj.value = element.slice(element.indexOf("]") + 1);
            } else {
              obj.title = "";
              obj.value = element;
            }
            this.meansList.push(obj);
          }
        }
      }
      this.diseaseInfo = results;
      this.diseaseInfoShow = true;
    },

    getCalamitySearch() {
      if (this.calamityTypeName || this.cropName) {
        this.commitForm();
      } else {
        this.$notify.error({
          title: "错误",
          message: "请选择分类或输入关键词",
        });
      }
    },

    handleCurrentChange(val) {
      this.commitForm(val);
    },
    async commitForm(pn = 1) {
      const { code, results } = await queryDiseaseListApi({
        pn,
        ps: 15,
        diseaseTypeID: this.diseaseTypeID,
        key_word: this.calamityTypeName,
        cropName: this.cropName,
      });
      if (code !== 0) return;
      if (this.diseaseTypeID == 2) {
        this.diseaseCount = results.count;
      } else {
        this.count = results.count;
      }
      this.list = results.data;
      this.pn = results.page_num;
      this.ps = results.page_size;
    },
  },
};
</script>

<style lang="less" scoped>
.knowledge {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 77px 0 23px;
  display: flex;
  justify-content: space-between;

  .column-L {
    width: 100%;
    margin: 0 12px 0 0;
    display: flex;
    /deep/.el-tabs {
      padding: 0 44px;
      .el-tabs__item {
        color: #fff;
      }
    }
    .result {
      width: 100%;
      height: 100%;
      background: #071a2c;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .result-top {
        width: 100%;
        height: 126px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .result-search {
          height: 126px;
          width: 100%;
          background: #0e2138;
          display: flex;
          align-items: center;
          padding: 0 44px;

          .name {
            margin-left: 36px;
            display: flex;
            align-items: center;

            span {
              font-size: 14px;
              margin-right: 20px;
            }

            /deep/.el-input {
              width: 200px;
            }

            /deep/.el-input__inner {
              width: 200px;
              background-color: #081c30;
              border: 1px solid #385576;
              color: #57708d;
              font-size: 14px;
            }
          }

          .searchBtn {
            width: 90px;
            height: 40px;
            background: #3e90e5;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;
            margin-left: 20px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }

          .calamity-type-name.name {
            margin-left: 0;

            /deep/.el-input {
              width: 253px;

              .el-input__inner {
                width: 100%;
              }
            }
          }
        }
      }
      .show-box {
        width: 100%;
        min-height: calc(100% - 200px);
        padding: 0 0 20px 0;
        .disease-list {
          width: 100%;
          height: 97%;
          background: #0e2138;
          display: flex;
          flex-direction: column;
          padding: 32px 0 0 0;
          margin-bottom: 10px;
          .disease-list-items {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .disease-list-items-main,
            .insect-box,
            .weed-box,
            .disease-box {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              padding: 0 30px;

              .disease-list-items-main-items {
                width: 20%;
                padding: 0 12px;
                font-size: 12px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                margin: 0 0 24px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                &:hover {
                  span {
                    color: #3e90e5;
                  }
                }

                // &.active {
                //   background: #173355;
                //   border-left-color: #3e90e5;
                // }
                .imgs {
                  width: 144px;
                  height: 144px;
                  margin-bottom: 8px;
                  /deep/.image-slot {
                    width: 144px;
                    height: 144px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    font-size: 32px;
                    background-color: rgb(245, 247, 250);
                    color: #999;
                  }
                }
                span {
                  color: #fff;
                }
              }
            }
            .insect-box {
              height: calc(100% - 60px);
            }
          }
        }
      }
    }

    .info-box {
      width: 100%;
      height: 100%;
      background: #071a2c;
      padding: 24px 40px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }

      .info-title {
        width: 100%;
        display: flex;
        margin-bottom: 25px;
        padding-right: 30px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .info-left-line {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          padding: 0 0 0 15px;
          position: relative;

          &::before {
            content: "";
            background-color: #3e90e5;
            width: 4px;
            height: 18px;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }

        .return-btn {
          width: 90px;
          height: 33px;
          background: #224d7f;
          border-radius: 17px;
          font-size: 16px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          img {
            width: 17px;
            height: 17px;
            margin-right: 8px;
          }
        }
      }

      .cell-title {
        width: 100%;
        border-bottom: 1px solid #224d7f;
        margin-bottom: 28px;

        span {
          display: inline-block;
          width: 104px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #224d7f;
          border-radius: 2px 2px 0 0;
          font-size: 14px;
          color: #fff;
        }
      }

      .other-col {
        margin-bottom: 15px;
        width: 100%;
        display: flex;

        .content-title {
          width: 81px;
          height: 25px;
          background: #081c30;
          font-size: 14px;
          color: #3e90e5;
          display: inline-block;
          margin-right: 12px;
          line-height: 25px;
          text-align: center;
        }

        .content-main {
          width: calc(100% - 81px);
          font-size: 14px;
          color: #fff;
          display: inline-block;
          line-height: 25px;
        }
      }

      .imgs-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        /deep/.el-img {
          width: 197px;
          height: 144px;
          margin: 0 24px 14px 0;
          cursor: pointer;

          &:nth-of-type(8n) {
            margin-right: 0;
          }
          .image-slot {
            width: 197px;
            height: 144px;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 32px;
            background-color: rgb(245, 247, 250);
            color: #999;
          }
        }
      }

      .means-list {
        width: 100%;

        .means-list-items {
          width: 100%;

          .p-title {
            width: 104px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #173355;
            border-radius: 16px 16px 16px 0;
            font-size: 14px;
            color: #3e90e5;
            margin-bottom: 12px;
          }

          .p-value {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
